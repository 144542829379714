// Original and the models by Bruno Simon: https://threejs-journey.com

import { render } from 'react-dom'
import React, { Suspense } from 'react'
import { Loader } from '@react-three/drei'
import './styles.css'
import App from './App'
import { FloorButton } from './view/floor-button'
import { A11yAnnouncer } from '@react-three/a11y'
import { BackToZepButton } from './view/back-to-zep'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Routes, Route } from 'react-router-dom'


import { ThemeProvider } from '@mui/material'
import { theme } from './view'
import { InitialIndicator } from './view/initial-indicator'
import { ShowCreatorList } from './view/show-creator-list'
import { Values } from './values'

render(
  // <>
  //   <Suspense fallback={null}>
  //     <App />
  //     {/*<OpenWorld />*/}
  //   </Suspense>
  //   <Loader />
  // </>
  //
  //
  //
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<div style={{ position: 'relative' }}>
        <div style={{
          width: '100vw',
          height: '100vh',
          zIndex: 0,
          // fontFamily: Values.fonts.gothicA1
          // backgroundColor: 'transparent',
        }}>
          <Suspense fallback={null}>
            <App />
          </Suspense>
          <Loader />
        </div>
        {/*<div style={{*/}
        {/*  fontSize: 40, color: 'black',*/}
        {/*  zIndex: 99, position: 'absolute', left: 0, top: 0,*/}
        {/*  width: '90vw', height: '90vh',*/}
        {/*  backgroundColor: 'white',*/}
        {/*}}>*/}
        {/*  <Suspense fallback={null}>*/}
        {/*    <Canvas*/}
        {/*      flat={false} dpr={[1, 2]}*/}
        {/*      // camera={{ fov: 25, position: [0,0,0] }}*/}
        {/*    >*/}
        {/*      <color attach='background' args={['#080808']} />*/}
        {/*      <ambientLight />*/}
        {/*      <OrbitControls makeDefault maxøDistance={3}*/}
        {/*      />*/}
        {/*      <Beetle />*/}
        {/*    </Canvas>*/}
        {/*  </Suspense>*/}
        {/*</div>*/}
        {/*<div style={{*/}
        {/*  position: 'fixed',*/}
        {/*  left: '50%',*/}
        {/*  bottom: 40,*/}
        {/*  // left: '30vw',*/}
        {/*  // justifySelf: 'center',*/}
        {/*  // alignSelf: 'center'*/}
        {/*}}>*/}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BackToZepButton />
          <FloorButton />
          <InitialIndicator />
          <ShowCreatorList />
        </ThemeProvider>
        {/*<ViewIndex />*/}
        {/*<BottomModalSheet/>*/}

        {/*</div>*/}
      </div>}>
      </Route>
    </Routes>
  </BrowserRouter>
  ,
  document.getElementById('root'),
)
