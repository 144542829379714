import { Canvas } from '@react-three/fiber'
import { Html, Plane, PresentationControls, Stars, Stats, useBounds, Cloud, useGLTF } from '@react-three/drei'
import React, { useEffect, useRef } from 'react'
import { RoomV4 } from './components/v4/room-v4'
import mainStore from './state'
import { ViewIndex } from './view'
import { A11yAnnouncer } from '@react-three/a11y'
import { CameraControl } from './components/v4/CameraControl'
import { Values } from './values'
import * as  qs from 'query-string'
import { useLocation } from 'react-router-dom'
import * as _ from 'lodash'
import { Illustrators } from './values/illustrators'
export default function App() {
  const statRef = useRef(null)
  const location = useLocation() //바뀐 부분
  // const { camera, gl } = useThree()
  //

  // const backgroundModel = useGLTF(`/background.glb`)


  useEffect(() => {
    const parsed: { c?: string } = qs.parse(location.search)
    // console.log(parsed)
    const idxFound = _.findIndex(Illustrators, (o) => {
      return o.nameDev === parsed.c
    })
    if (idxFound > -1) {
      mainStore.setState({
        illustrator: Illustrators[idxFound],
      })
    }
  }, [location.search])
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('asjdioajsiodioioasj');
  //     setZoom(1)
  //   }, 2000)
  // }, [])
  // const openModal = mainStore((s) => s.openModal)

  // if (openModal) return <div></div>
  return (
    <Canvas
      // flat={false}
      dpr={[1, 1.7]}
      // dpr={1.5}
      // dpr={Math.max(window.devicePixelRatio * 0.9, 2)}
      // @ts-ignore
      // colorManagement
      // shadowMap
      // @ts-ignore
      // shadows='true'
      camera={{
        fov: 25,
        position: [6.8, 2, 6.5],
      }}
    >
      <color attach='background' args={['black']} />
      {/*<primitive object={backgroundModel.scene} position={[0,-1,0]} />*/}
      {/*<Stars radius={1} depth={20} count={1000} factor={3} saturation={0} fade />*/}
      {/*<Cloud*/}
      {/*  opacity={0.5}*/}
      {/*  speed={0.4} // Rotation speed*/}
      {/*  width={10} // Width of the full cloud*/}
      {/*  depth={1.5} // Z-dir depth*/}
      {/*  segments={20} // Number of particles*/}
      {/*/>*/}
      {/*<CameraControl />*/}
      <PresentationControls
        snap={true}
        global={true}
        zoom={
          1
          // zoom
        }
        rotation={[0,0, 0]}
        polar={[0, 0]}
        azimuth={
          [-Math.PI / 4, Math.PI / 4]
        }
        config={{ mass: 1, tension: 170, friction: 26 }}
      >
      {/*<Stats*/}
      {/*  showPanel={0}*/}
      {/*  className='stats'*/}
      {/*/>*/}
      <RoomV4 />
      {/*<Level></Level>*/}
      </PresentationControls>
      <Html>
        <ViewIndex />
      </Html>
    </Canvas>
  )
}
