import { SpringValue } from '@react-spring/three'
import create from 'zustand'

// export const FLOOR_DATA = [
//   {
//     x: 0,
//     y: 2.5,
//     z: 8,
//   },
//   {
//     x: 1.5,
//     y: 4.7,
//     z: 6,
//   },
//   {
//     x: 0,
//     y: 6.5,
//     z: 4.5,
//   },
// ]

export const FLOOR_DATA = [
  {
    x: -0.5,
    y: -0.9,
    z: -0.5,
  },
  {
    x: -0.5,
    y: -3.2,
    z: 1.6,
  },
  {
    // x: 0,
    // y: -4.8,
    // z: 0,
    x: 1.5,
    y: -5.1,
    z: 1.5,
  },
]

class CameraController {
  orbitEnabled = false
  currentFloor = 0
  x = new SpringValue(FLOOR_DATA[0].x)
  y = new SpringValue(FLOOR_DATA[0].y)
  z = new SpringValue(FLOOR_DATA[0].z)

  ascend = async () => {
    // console.log('asjdioajdioodijodijaodiajsoi')
    this.orbitEnabled = false
    const floor = this.currentFloor + 1
    await Promise.all([this.x.start(FLOOR_DATA[floor].x), this.y.start(FLOOR_DATA[floor].y), this.z.start(FLOOR_DATA[floor].z)])
    // console.log('@IOP#J@OP!IJ#!@O#JIO!J@OI#JIO');
    this.currentFloor = floor
    this.orbitEnabled = true
  }

  descend = async() => {
    this.orbitEnabled = false
    const floor = this.currentFloor - 1
    // this.x.start(FLOOR_DATA[floor].x)
    // this.y.start(FLOOR_DATA[floor].y)
    // this.z.start(FLOOR_DATA[floor].z)
    await Promise.all([this.x.start(FLOOR_DATA[floor].x), this.y.start(FLOOR_DATA[floor].y), this.z.start(FLOOR_DATA[floor].z)])
    this.currentFloor = floor
    this.orbitEnabled = true
  }

  reset = () => {
    this.x.start(FLOOR_DATA[0].x)
    this.y.start(FLOOR_DATA[0].y)
    this.z.start(FLOOR_DATA[0].z)
    this.currentFloor = 0
  }
}

export const cameraController = new CameraController()

