import { rgbaColor, Values } from '../values'
import { Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import mainStore from '../state'

export const BackToZepButton = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const illustrator = mainStore((state) => state.illustrator)
  // console.log(`/icons/${illustrator.roomType}.png`);
  // useEffect(() => {
  //   const aa = {
  //     a: 1,
  //     b: 2,
  //     c: this,
  //   }
  //   console.log(aa)
  // }, [])
  return (
    <div style={{
      // display: 'flex',
      // position: 'fixed',
      // left: `16px`,
      // top: 16,
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        left: `16px`,
        top: 16,
        borderRadius: 16,
        backgroundColor: rgbaColor(0, 0, 0, 0.35),
        cursor: 'pointer',
        width: 44,
        height: 44,
        objectFit: 'contain',
      }}
           onClick={() => {
             setOpenDialog(true)
           }}
      >
        <img src={`/icons/exit.png`} style={{ width: 21, height: 21, objectFit: 'contain' }} />
      </div>
      {/*<GoBackDialog*/}
      {/*  open={openDialog}*/}
      {/*  handleClose={() => {*/}
      {/*    setOpenDialog(false)*/}
      {/*  }}*/}
      {/*/>*/}
      <QuestionDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false)
        }}
      />
    </div>
  )
}

const GoBackDialog = ({ open, handleClose }: { open: boolean; handleClose(): void }) => {
  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      style={{
        // width: 300
      }}
    >
      <div style={{
        // width: '100%',
        // height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 17,
        // width: `calc(100vw - 32px)`,
        // height: `calc(100vh - 32px)`,
        width: 343,
        height: 355,
        // minWidth: 340,
        // maxWidth: 350,
        // maxHeight: 400,
        // marginLeft: 16, marginRight: 16,
        background: `linear-gradient(to top right, #8094FF, #6939F1)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: 21,
        border: 'none',
        outline: 'none',
      }}>
        <img
          src={'/icons/pumpkin.png'}
          style={{
            width: 136, height: 136,
            // backgroundColor: 'red',
          }}
        />
        <div style={{ height: 12 }} />
        <div>
          2D 룸으로 되돌아갈까요?
        </div>
        <div style={{ height: 48 }} />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 17,
          fontWeight: 600,
        }}
        >
          <div
            style={{
              padding: 6,
              paddingLeft: 14, paddingRight: 14,
              borderRadius: 100,
              backgroundColor: 'white',
              color: '#8094FF',
              marginRight: 12,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            네, 나가겠습니다
          </div>
          <div
            style={{
              padding: 6,
              paddingLeft: 14, paddingRight: 14,
              borderRadius: 100,
              border: `1px solid white`,
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClose()
            }}
          >
            아니요
          </div>
        </div>
      </div>
    </Modal>
  )
}


const QuestionDialog = ({ open, handleClose }: { open: boolean; handleClose(): void }) => {
  const navigate = useNavigate()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      style={{
        // width: 300
      }}
    >
      <div style={{
        // width: '100%',
        // height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 17,
        // width: `calc(100vw - 32px)`,
        // height: `calc(100vh - 32px)`,
        width: 343,
        // height: 355,
        // minWidth: 340,
        // maxWidth: 350,
        // maxHeight: 400,
        // marginLeft: 16, marginRight: 16,
        background: `linear-gradient(to top right, #8094FF, #6939F1)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: 21,
        border: 'none',
        outline: 'none',
      }}>
        <img
          src={'/imgs/questionImage.png'}
          style={{
            marginTop: 4,
            width: 136, height: 136,
            // backgroundColor: 'red',
          }}
        />
        {/*<div style={{ height: 4 }} />*/}
        <div style={{
          fontSize: 28,
          textAlign: 'center',
          lineHeight: 1.3,
          fontFamily: Values.fonts.dgm,
        }}>
          서비스 평가 설문에
          <br />
          참여해주세요!
        </div>
        <div style={{
          fontSize: 15,
          lineHeight: 1.35,
          marginTop: 16,
        }}>
          추첨을 통해 응답자 10분에게<br />
          스타벅스 기프티콘을 드립니다.
        </div>
        <div style={{ height: 24 }} />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 17,
          fontWeight: 600,
          marginBottom: 28,
        }}
        >
          <div
            style={{
              padding: 6,
              paddingLeft: 16, paddingRight: 16,
              borderRadius: 14,
              backgroundColor: 'white',
              color: '#8094FF',
              marginRight: 12,
              cursor: 'pointer',
            }}
            onClick={() => {
              // navigate(-1)
              window.location.href = 'https://forms.gle/U5stHgtJCEWFU9JZA'
            }}
          >
            설문 참여하기
          </div>
          <div
            style={{
              padding: 6,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 14,
              border: `1px solid white`,
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClose()
              navigate(-1)
            }}
          >
            아니요
          </div>
        </div>
      </div>
    </Modal>
  )
}
