import { OrbitControls } from '@react-three/drei'
import React from 'react'
import { useThree } from '@react-three/fiber'

export const CameraControl = () => {

  const { camera, gl } = useThree()
  //
  // useFrame(() => {
  //   // console.log(cameraController.orbitEnabled)
  //   // if (!cameraController.orbitEnabled) {
  //   const xAxis = cameraController.x.get()
  //   const yAxis = cameraController.y.get()
  //   const zAxis = cameraController.z.get()
  //   // console.log(yAxis)
  //   camera.position.x = xAxis
  //   camera.position.y = yAxis
  //   camera.position.z = zAxis
  //   // console.log(cameraController.orbitEnabled);
  //   // console.log(camera);
  //   // }
  //
  // })

  // useEffect(() => {
  //   console.log(cameraController.currentFloor)
  //   const xAxis = cameraController.x.get()
  //   const yAxis = cameraController.y.get()
  //   const zAxis = cameraController.z.get()
  //   console.log(yAxis)
  //   camera.position.x = xAxis
  //   camera.position.y = yAxis
  //   camera.position.z = zAxis
  // }, [cameraController.currentFloor])

  // useEffect(() => {
  //   console.log(camera)
  //   // camera.rotation = [0, -Math.PI / 4, 0]
  //   // camera.rotation= new THREE.Euler( 0, -Math.PI / 4, 0, 'XYZ' );
  //   // camera.rotation.y = -Math.PI / 4
  // }, [])
  return (
    <OrbitControls
      camera={camera} makeDefault
      // enableZoom
      enabled={
        // false
        // cameraController.orbitEnabled
        true
      }
    />
  )
}
