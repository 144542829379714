import { useGLTF } from '@react-three/drei'
import React, { useRef } from 'react'

import { useFrame } from '@react-three/fiber'
import { cameraController } from '../../state/camera'
import mainStore from '../../state'
import { GoodsModel } from './goods_model'
import { GoodsGroupModel } from './goods-group-model'


export const RoomV4 = () => {
  const groupRef = useRef()

  const illustrator = mainStore((state) => state.illustrator)

  // 이거 폴더 들어가니까 못불러옴...그래서 최상위 루트에 넣어놓음
  // const model = useGLTF(`/rooms/${illustrator.roomType}.glb`)
  // const goods = useGLTF(`/illustrators/${illustrator.nameDev}.glb`)


  const background = useGLTF(`/background_${illustrator.roomType}.glb`)
  const room = useGLTF(`/room_${illustrator.roomType}.glb`)
  const goods = useGLTF(`/creator_${illustrator.nameDev}.glb`)

  // const [roomModel, setRoomModel] = useState<GLTF & ObjectMap>()
  // const [goodsModel, setGoodsModel] = useState<GLTF & ObjectMap>()

  // useEffect(() => {
  //   // setRoomModel(useGLTF(`/room_${illustrator.roomType}.glb`))
  //    room = useGLTF(`/room_${illustrator.roomType}.glb`)
  //    goods = useGLTF(`/creator_${illustrator.nameDev}.glb`)
  // }, [illustrator.nameDev, illustrator.roomType])

  // const floor = mainStore((s) => s.floor)

  // useEffect(() => {
  //   // console.log(goods.nodes)
  //   console.log(`/rooms/${illustrator.roomType}.glb`);
  //   console.log(`/illustrators/${illustrator.nameDev}.glb`);
  // }, [])


  // useMemo(() => {
  //   Object.values(model.nodes).forEach(obj => {
  //     // @ts-ignore
  //     obj.isMesh && Object.assign(obj, { castShadow: true, receiveShadow: true })
  //   })
  // }, [model.nodes])


  useFrame(() => {
    if (groupRef && groupRef.current) {
      // @ts-ignore
      groupRef.current.position.x = cameraController.x.get()
      // @ts-ignore
      groupRef.current.position.y = cameraController.y.get()
      // @ts-ignore
      groupRef.current.position.z = cameraController.z.get()
    }
  })


  // console.log(currentFloor);

  // const LightOf1F = () => {
  //   return <group>
  //     <spotLight
  //       position={[0, 4, 0.3]}
  //       distance={10}
  //       angle={1.2}
  //       shadow-bias={-0.0019}
  //       penumbra={.5}
  //       shadow-camera-near={0.1}
  //       shadow-camera-far={20}
  //       intensity={1.5}
  //       // @ts-ignore
  //       focus={0.4}
  //       castShadow
  //       shadowCameraVisible
  //       target={refOf1F.current}
  //     >
  //     </spotLight>
  //   </group>
  // }
  // const LightOf2F = () => {
  //   return (
  //     <group>
  //       <spotLight
  //         position={[0.6, 6, -1.4]}
  //         // target={[0, 3, -1]}
  //         distance={10}
  //         angle={1.2}
  //         shadow-bias={-0.0019}
  //         penumbra={.5}
  //         shadow-camera-near={0.1}
  //         shadow-camera-far={20}
  //         intensity={1.5}
  //         // @ts-ignore
  //         focus={0.4}
  //         castShadow
  //         shadowCameraVisible
  //         target={refOf2F.current}
  //       >
  //       </spotLight>
  //       <pointLight position={[0, 3.75, -2.7]} intensity={0.6} distance={1}>
  //       </pointLight>
  //       <pointLight position={[-0.4, 3.75, -2.7]} intensity={0.6} distance={1}>
  //       </pointLight>
  //       <pointLight position={[.4, 3.75, -2.7]} intensity={0.6} distance={1}>
  //       </pointLight>
  //     </group>
  //   )
  // }
  // const LightOf3F = () => {
  //   return (
  //     <group>
  //       <spotLight
  //         position={[-2, 8.5, -1.4]}
  //         // target={[0, 3, -1]}
  //         distance={10}
  //         angle={1.2}
  //         shadow-bias={-0.0019}
  //         penumbra={.5}
  //         shadow-camera-near={0.1}
  //         shadow-camera-far={20}
  //         intensity={1.5}
  //         // @ts-ignore
  //         focus={0.4}
  //         castShadow
  //         shadowCameraVisible
  //         target={refOf3F.current}
  //       />
  //       <pointLight position={[-2.62, 5.83, -2.72]} intensity={0.6} distance={2}>
  //       </pointLight>
  //       <pointLight position={[-2.1, 5.59, -2.72]} intensity={0.6} distance={2}>
  //       </pointLight>
  //       <pointLight position={[-1.5, 5.9, -2.72]} intensity={0.6} distance={2}>
  //       </pointLight>
  //     </group>
  //   )
  // }
  //
  // const LightWidget = () => {
  //   if (floor === 0) {
  //     return <group>
  //       <LightOf1F />
  //       <LightOf2F />
  //     </group>
  //   }
  //   if (floor === 1) {
  //     return (
  //       <group>
  //         <LightOf1F />
  //         <LightOf2F />
  //         <LightOf3F />
  //       </group>)
  //   }
  //   return (
  //     <group>
  //       <LightOf2F />
  //       <LightOf3F />
  //     </group>
  //   )
  // }

  return (
    <group
      ref={groupRef}
    >
      <ambientLight intensity={0.1} />
      {/*<hemisphereLight position={[10,10,10]}></hemisphereLight>*/}
      <pointLight position={[10, 10, 10]} intensity={0.95} />
      <primitive object={background.scene} position={[0, 0, 0]} />
      {/*<primitive object={room.scene} />*/}
      {room.scene.children.map((child, idx) => {
        const key = `MODEL_SCENE_CHILD_WITH_IDX_${idx}`
        // @ts-ignore
        if (child.isMesh) {
          return <mesh
            key={key}
            {...child}
            onClick={() => {
              if (child.name.includes('Asset_NoticeBoard_Link')) {
                console.log(child.name)
                if (child.name.includes('002')) {
                  // 인스타그램
                  window.open(illustrator.instagram, '_blank')
                }
              }
            }}
          >
          </mesh>
        }

        // @ts-ignore
        return <group
          key={key}
          position={child.position}
          {...child}
          // scale={undefined}
        >
          {child.children.map((c2, i) => {
            const childKey = `MODEL_SCENE_CHILD_SECOND_WITH_IDX_${i}_${c2.uuid}`
            return (<mesh key={childKey} {...c2} />)
          })}
        </group>
      })}
      {/*<spotLight*/}
      {/*  position={[0.6, 6, -1.4]}*/}
      {/*  // target={[0, 3, -1]}*/}
      {/*  distance={10}*/}
      {/*  angle={1.2}*/}
      {/*  // shadow-bias={-0.0019}*/}
      {/*  penumbra={.5}*/}
      {/*  shadow-camera-near={0.1}*/}
      {/*  shadow-camera-far={20}*/}
      {/*  intensity={1.5}*/}
      {/*  // @ts-ignore*/}
      {/*  focus={0.4}*/}
      {/*  castShadow*/}
      {/*  shadowCameraVisible*/}
      {/*  // target={refOf2F.current}*/}
      {/*/>*/}
      {/*<primitive object={model.scene}></primitive>*/}
      {/*<Sky distance={1000} sunPosition={[10, 10, 10]} inclination={0} azimuth={0.25} />*/}

      {/*<LightWidget />*/}
      {goods.scene.children.map((child, idx) => {
        const key = `MODEL_SCENE_CHILD_WITH_IDX_${idx}`
        // @ts-ignore
        if (child.isMesh) {
          if (child.name.includes('Goods')) {
            console.log(child.name)
            // @ts-ignore
            return <GoodsModel
              key={key}
              {...child} />
            // return (<mesh key={key} {...child} >
            //   {/*<meshPhongMaterial color='rgb(66, 133, 100)' opacity={1} transparent />*/}
            // </mesh>)
          }
          return <mesh
            key={key}
            {...child}
            onClick={() => {
              if (child.name.includes('Asset_NoticeBoard_Link')) {
                console.log(child.name)
                if (child.name.includes('002')) {
                  // 인스타그램
                  window.open(illustrator.instagram, '_blank')
                }
              }
            }}
          >
            {/*<meshPhongMaterial color='rgb(66, 133, 244)' opacity={1} transparent />*/}
          </mesh>
        }

        if (child.name.includes('Goods')) {
          // @ts-ignore
          return <GoodsGroupModel key={key}  {...child} />
        }

        // @ts-ignore
        return <group
          key={key}
          position={child.position}
          {...child}
          // scale={undefined}
        >
          {child.children.map((c2, i) => {
            const childKey = `MODEL_SCENE_CHILD_SECOND_WITH_IDX_${i}_${c2.uuid}`
            // if (child.name.includes('Goods')) {
            //   // console.log(child.name)
            //   // return (<mesh key={childKey} {...c2} >
            //   //   {/*<meshPhongMaterial color='rgb(66, 133, 100)' opacity={1} transparent />*/}
            //   // </mesh>)
            //   // @ts-ignore
            //   return <GoodsModel
            //     key={childKey}
            //     {...c2}
            //   />
            // }
            return (<mesh key={childKey} {...c2} />)
          })}
        </group>
      })}
    </group>
  )
}

