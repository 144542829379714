import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Cone, Html, Line } from '@react-three/drei'
import mainStore from '../../state'
import * as THREE from 'three'
import { BufferGeometry, Euler } from 'three'
import { useSpring } from '@react-spring/three'
import { useFrame } from '@react-three/fiber'
import { ClickIndicator } from './elements/click-indicator'

export const GoodsModel = forwardRef(
  ({
     disableMouseAction = false,
     setBounds = false,
     removeClickIndicator = false,
     ...props
   }: {
    props: any, disableMouseAction?: boolean, setBounds?: boolean,
    removeClickIndicator?: boolean
  }, ref) => {
    const [hovered, setHover] = useState(false)
    // const openModal = mainStore((s) => s.openModal)
    // const storeModel = mainStore((s) => s.model)
    // const model = mainStore((state) => state.model)

    useEffect(() => {
      // console.log(props);
      if (ref) {
        // @ts-ignore
        // console.log(ref.current.geometry)
      }
    }, [])
    const ModelMaterial = () => {
      if (hovered) return <meshPhongMaterial color='rgb(66, 133, 244)' opacity={1} transparent />
      // @ts-ignore
      if (props.material.type.includes('Basic')) {
        // @ts-ignore
        return <meshBasicMaterial {...props.material} />
      }
      // @ts-ignore
      return <meshStandardMaterial {...props.material} />
    }
    // @ts-ignore
    // if (model?.uuid === props?.uuid) {
    //   return <mesh></mesh>
    // }

    const onClick = () => {
      if (!disableMouseAction){
        // @ts-ignore
        if (props.name.toLowerCase().includes('Goods_FrameOnDesk'.toLowerCase())){
          mainStore.setState({
            openCreatorIntro: true,
          })
          return;
        }
        mainStore.setState({
          model: { ...props, position: [0, 0, 0] },
          openModal: true,
        })
      }
    }
    return (
      <group
        onPointerOver={(e) => {
          if (!disableMouseAction) {
            setHover(true)
          }
        }}
        onPointerOut={() => {
          if (!disableMouseAction) {
            setHover(false)
          }
        }}
        onClick={onClick}
      >
        {/*/!*@ts-ignore*!/*/}
        {
          !removeClickIndicator ? <ClickIndicator
            // @ts-ignore
            name={props.name}
            // @ts-ignore
            position={props.position}
            // @ts-ignore
            rotation={props.rotation}
            // @ts-ignore
            geometry={props.geometry}
            // @ts-ignore
            scale={props.scale}
            onClick={onClick}
          /> : <mesh />
        }
        <mesh
          ref={ref}
          matrixAutoUpdate={false}
          // @ts-ignore
          // geometry={mode.geometry}
          // @ts-ignore
          // material={model.material}
          // position={[0,0,0]}
          // rotation={[Math.PI / 2, -Math.PI / 9, 0]}
          {...props}
        >
          <ModelMaterial />
          {/*<line>*/}
          {/*  /!*@ts-ignore*!/*/}
          {/*  <edgesGeometry {...props.geometry}>*/}
          {/*    <color attach='background' args={['red']} />*/}
          {/*  </edgesGeometry>*/}
          {/*</line>*/}
        </mesh>
      </group>
    )
  })


