import React, { useEffect, useState } from 'react'

export const MAX_WIDTH = 480;

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 375,
    height: 635,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth > MAX_WIDTH ? MAX_WIDTH : window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  const responsive = (pixel: number) => (windowSize.width / 375) * pixel
  // console.log(window.innerWidth);
  // console.log(windowSize);
  return {
    ...windowSize,
    responsive,
    letterSpacing: (
      letterTrackingValue: number,
      fontPixel: number,
    ): number => ((letterTrackingValue * responsive(fontPixel)) / 1000),
  }
}
