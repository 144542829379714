import { IIllustrator } from '../types/interfaces'

export enum ERoomTypes {
  Pumpkin = 'pumpkin',
  Mushroom = 'mushroom',
  Apple = 'apple',
  Wood = 'wood',
}

export const Illustrators: IIllustrator[] = [
  {
    displayName: '미선',
    nameDev: 'ordinaryms',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'anmis9012@gmail.com',
    instagram: 'https://www.instagram.com/ordinaryms_/',
    roomType: ERoomTypes.Mushroom,
    bio: `안녕하세요. 귀여운 미니 픽셀 세상을 만드는 미선 작가입니다.
조그맣고 앙증맞은 미니선데이에서 느긋한 시간 보내세요 :3

Instagram : @ordinaryms_
Twitter : @ordinaryms_
Homepage : minisunday.studio
`,
  },
  {
    displayName: '무브멍트',
    nameDev: 'movement',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'branch0405@naver.com',
    portal: 'http://www.hongik-emcr.com/projects/list?w=%EB%AC%B4%EB%B8%8C%EB%A9%8D%ED%8A%B8',
    roomType: ERoomTypes.Pumpkin,
    bio: `사람들에게 위대한 멍을 전파하기 위해 모인 멍을 사랑하는 무브멍트 위대한 멍과 함께, 무브멍트!`,
  },
  // {
  //   displayName: '핑크콩',
  //   nameDev: 'pinkkong',
  //   backgroundImgs: function() {
  //     return [
  //       // @ts-ignore
  //       `/creator-imgs/${this?.nameDev}.webp`,
  //     ]
  //   },
  //   instagram: 'https://www.notion.so/concat5/0120e4606d9b4e23af9f81d750676ff2',
  //   roomType: ERoomTypes.Pumpkin,
  // },
  {
    displayName: 'PeeP',
    nameDev: 'peep',
    email: 'arieun00@gmail.com',
    instagram: 'https://www.instagram.com/peepbbip/',
    roomType: ERoomTypes.Mushroom,
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    bio: `작고 소중한 것들을 위한 디자인을 합니다. 오늘은 숲속마을에 숨어있는 이끼들의 이야기를 들어볼까요?`
  },
  {
    displayName: '서준원',
    nameDev: 'seoJunWon',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'samseo7@naver.com',
    instagram: 'https://www.instagram.com/cage_house/',
    roomType: ERoomTypes.Apple,
    bio: `결코 외면할 수 없는 불법 번식장 속 강아지들의 이야기, "우리집을 소개합니다." \<우리집\> 프로젝트는 불편하고 거부감이 드는 ‘불법 번식장’이라는 주제를 코믹 스트립 형식으로 거부감을 줄이고 비극성을 강화시켰습니다.`,
  },
  {
    displayName: 'JOO',
    nameDev: 'pjhjenny',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'pjhjenny@naver.com',
    instagram: 'https://ewhadesign.com/2021/artwork/118/',
    roomType: ERoomTypes.Mushroom,
    bio:`하늘을 자유롭게 날아다니는 구르미들! 
다양한 모습의 구름들을 그립니다.
구르미들의 숲속 피크닉에 놀러와 힐링하고 가세요!
`
  },
  {
    displayName: '디어무구',
    nameDev: 'dearMugu',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'dear_mugu@naver.com',
    instagram: 'https://www.instagram.com/dear.mugu/',
    roomType: ERoomTypes.Mushroom,
    bio: `엉망진창이지만 어떻게든 굴러가고 있는 일상 속 이야기를 그리는 "디어무구"입니다.
포동포동한 아기 동물 네 친구들의 데일리 라이프
여러분의 이야기에 귀여움 한 스푼 더해보세요!
`,
  },
  {
    displayName: '행운가득 레키박스',
    nameDev: 'lekiBox',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'leki3000@naver.com',
    instagram: 'https://www.instagram.com/leki_box/',
    roomType: ERoomTypes.Apple,
    bio: `즐거움과 행복의 순간을 담는 레키박스 



여행🧳가거나, 특별한 일🎂이 있을 때, 

🍀일상에서 즐거운 순간🍀엔 

"아! 이 느낌을 그림으로 담고 싶다" 란 

생각이 들어요🙈

 

(((막상 작업을 시작하면, 

즐거움만 있는 건 아니고 

꼼꼼하게 고통받기도 하지만요 😝)))

 

레키의 친구들은 레키를 보면 기분이 좋아진대요 

여러분들에게도 그랬으면 좋겠습니다💓
`,
  },
  {
    displayName: '소로로',
    nameDev: 'sororo',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'sororo1108@gmail.com',
    instagram: 'https://www.instagram.com/so.ror0/',
    roomType: ERoomTypes.Apple,
    bio: `"모든 하루가 소중하길", 꼬마유령 하루와 동물친구들을 그립니다. 대장을 맡고 있는 소로롭니다.`,
  },
  {
    displayName: '숲속마을친구들',
    nameDev: 'forestVillageFriends',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'kksc1089@naver.com',
    instagram: 'https://instagram.com/forestvillage_friends/',
    roomType: ERoomTypes.Wood,
    bio: `만두를 사랑하는 토끼 맹두와 빵을 사랑하는 다람쥐 램쥐스를 그리고있는 작가, 나니입니다:)`,
  },
  {
    displayName: '지수',
    nameDev: 'gsoo',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'loooooow@naver.com',
    instagram: 'https://www.instagram.com/gsoo_illustrator/',
    roomType: ERoomTypes.Wood,
    bio: `[지수 - DOONDOON BAKERY - coovengers)
안녕하세요. 숲 속 둔둔 베이커리에 오신 것을 환영합니다!
 
푹신한 바게트 둔둔, 용감한 시나몬롤 어니, 꼬마 붕어빵 핑, 긍정 깨찰빵 홍, 방귀쟁이 베이글 똥똥, 똑똑한 마카롱 로기.
모두 우리가게 가족이에요!
 
아 참, 우리가게 시나몬 롤 정말 맛있는데.
설명할 길이 없네? 누가 입 소문 좀 내주세요!
 
@gsoo_illustrator

`,
  },
  {
    displayName: '쿠우마을 민쿠우',
    nameDev: 'minkuu',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'minkuu0810@naver.com',
    instagram: 'https://www.instagram.com/minkuume/',
    roomType: ERoomTypes.Mushroom,
    bio: `쿠우마을에 살고있는 귀여움이 가득한 늑대 하울이를 그리며 제품을 만듭니다. 아직은 쿠우마을에 혼자 살고있어요. 하울이와 친구가 되어줄래요?`
  },
  {
    displayName: '솜두',
    nameDev: 'somdoo',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'somdooooo2@gmail.com',
    instagram: 'https://www.instagram.com/somdo.o/',
    roomType: ERoomTypes.Wood,
    bio: `안녕하세요, 일러스트레이터 솜두입니다!
컨텐츠 브랜드 '온디어마인'을 운영하며 일상과 일상 속 작은 상상을 그림이나 영상, 글 그리고 제품 등에 담아 설렘과 따뜻한 마음을 전하는 작업들을 합니다.
코 없는 코끼리 솜뭉치들과 친구들을 담은 그림을 그립니다.
`,
  },
  {
    displayName: '컬리프렌즈',
    nameDev: 'curlyFriends',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'tata_00@naver.com',
    instagram: 'https://www.instagram.com/curly__friends/',
    roomType: ERoomTypes.Wood,
    bio: `아기 푸들 컬리와 친구들의 복슬곱슬한 이야기를 그립니다.`
  },
//   {
//     displayName: '하슈랜드',
//     nameDev: 'hasyuLand',
//     backgroundImgs: function() {
//       return [
//         // @ts-ignore
//         `/creator-imgs/${this?.nameDev}-1.webp`,
//         // @ts-ignore
//         `/creator-imgs/${this?.nameDev}-2.webp`,
//       ]
//     },
//     email: 'hasyuland@naver.com',
//     instagram: 'https://www.instagram.com/hasyuland/',
//     roomType: ERoomTypes.Mushroom,
//     bio: `말랑포근한 일러스트 소품샵, 하슈랜드입니다.
//
// "귀여운 건 좋지만 유치한 건 싫어!"
//
// 보기만 해도 웃음이 나는, 귀엽고 포근한 그림을 그리고
// 동심을 간직한 어른이들을 위한 굿즈를 만듭니다.
//
// 메타버스 일러스트페어에서 만나요! @hasyuland
// `,
//   },
  {
    displayName: '딩푸',
    nameDev: 'dingpu',
    backgroundImgs: function() {
      return [
        // @ts-ignore
        `/creator-imgs/${this?.nameDev}.webp`,
      ]
    },
    email: 'ghkddms4804@gmail.com',
    instagram: 'https://www.instagram.com/_dingpu._.u/',
    roomType: ERoomTypes.Wood,
    bio: `여러분 삶에 녹아들어 행복을 전하고 싶어하는 하베와 하코를 그리는 딩푸`,
    //     bio: `말랑포근한 일러스트 소품샵, 하슈랜드입니다.
//
// "귀여운 건 좋지만 유치한 건 싫어!"
//
// 보기만 해도 웃음이 나는, 귀엽고 포근한 그림을 그리고
// 동심을 간직한 어른이들을 위한 굿즈를 만듭니다.
//
// 메타버스 일러스트페어에서 만나요! @hasyuland
// `,
  },
]
