import React, { useEffect, useRef, useState } from 'react'
import { Box, Container, Modal } from '@mui/material'
import mainStore from '../state'
import { CameraControl } from '../components/v4/CameraControl'
import { Canvas } from '@react-three/fiber'
import { GoodsModel } from '../components/v4/goods_model'
import CloseIcon from '@mui/icons-material/Close'
import { isMobile } from 'react-device-detect'
import { GoodsGroupModel } from '../components/v4/goods-group-model'
import { Values } from '../values'
import { useWindowSize } from '../utils'


const BORDER_RADIUS = 12
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   // top: '0%',
//   // left: '0%',
//   // bottom: 0,
//   transform: 'translate(-50%, -50%)',
//   width: `${isMobile ? 95 : 70}vw`,
//   height: `${isMobile ? 90 : 60}vh`,
//   // height: '100%',
//   // bgcolor: 'background.paper',
//   // bgcolor: 'white',
//   border: 'none',
//   // border: '2px solid #000',
//   boxShadow: 24,
//   // p: 2,
//   // borderRadius: BORDER_RADIUS,
// }

export const ViewApp = () => {
  const { responsive, letterSpacing } = useWindowSize()

  const modelRef = useRef(null)
  const open = mainStore((state) => state.openModal)
  const model = mainStore((state) => state.model)
  const illustrator = mainStore((state) => state.illustrator)


  const [position, setPosition] = useState<[number, number, number]>([0, 0, 0])
  const [rotation, setRotation] = useState<[number, number, number]>([0, 0, 0])
  const [delta, setDelta] = useState<number>(.00001)
  const HEIGHT = 48
  const WIDTH = '100%'
  const CANVAS_DESCRIPTION_RATIO = 0.79
  const CHEVRON_WIDTH = 10
  const CHEVRON_HEIGHT = 20

  const handleOpen = () => {
    mainStore.setState({ openModal: true })
  }
  const handleClose = () => {
    mainStore.setState({ openModal: false, model: null })
  }

  useEffect(() => {
    if (model) {
      console.log(model.name)
      if (model.type === 'Mesh') {
        const xRange = model.geometry.boundingBox.max.x - model.geometry.boundingBox.min.x
        const yRange = model.geometry.boundingBox.max.y - model.geometry.boundingBox.min.y
        const zRange = model.geometry.boundingBox.max.z - model.geometry.boundingBox.min.z
        setDelta(1.5 / Math.max(xRange, yRange, zRange))
      } else {
        // console.log(model)
        // const delta = 1.6 / Math.max(xRange, yRange, zRange)
        let tempDelta = 0
        model.children.map((child) => {
          const xRange = child.geometry.boundingBox.max.x - child.geometry.boundingBox.min.x
          const yRange = child.geometry.boundingBox.max.y - child.geometry.boundingBox.min.y
          const zRange = child.geometry.boundingBox.max.z - child.geometry.boundingBox.min.z
          const childDelta = 1.4 / Math.max(xRange, yRange, zRange)
          // console.log(childDelta)
          if (tempDelta < childDelta) {
            // setDelta(childDelta)
            tempDelta = childDelta
          }
        })
        setDelta(tempDelta)
      }
      if (model.name.includes('HangingPoster')) {
        setPosition([0, 1, 0])
        // rotation = [Math.PI / 2,-Math.PI/4, Math.PI/2 ]
        setRotation([0, -Math.PI / 2, 0])
        setDelta(0.013)
      }
      if (model.name.includes('FrameOnDesk')) {
        setRotation([0, 0, 0])
        setPosition([0, -0.8, 0])
      }
      if (model.name.includes('Stickers')) {
        setPosition([0, 0, 0])
        setRotation([Math.PI / 2, -Math.PI / 2, 0])
      }
      if (model.name.includes('TShirt')) {
        setPosition([0, 1, 0])
        setRotation([0, -Math.PI / 4, 0])
      }
      if (model.name.includes('Mug')) {
        setPosition([0, -0.7, 0])
        setRotation([0, -Math.PI / (2.2), 0])
      }
      if (model.name.includes('Griptok_002')) {
        setPosition([0, -0.5, 0])
        setRotation([Math.PI / 8, Math.PI / 2, Math.PI / 8])
      }
      if (model.name.includes('Griptok_001')) {
        setPosition([0, -0.7, 0])
        setRotation([0, -Math.PI / 8, Math.PI / 8])
      }
      if (model.name.includes('_Frame_')) {
        setPosition([0, 0, 0])
        setRotation([0, 0, 0])
      }
      if (model.name.includes('_Tape_')) {
        if (model.name.includes('003')) {
          setPosition([0, -0.5, 0])
          setRotation([0, 0, 0])
          setDelta(4)
        } else {
          setPosition([0, -0.4, 0])
          setRotation([0, 0, 0])
          setDelta(4)
        }
      }
      if (model.name.includes('Phonecase')) {
        setPosition([0, 0, 0])
        setRotation([0, -Math.PI / 2, -Math.PI / 2])
      }
      if (model.name.includes('_Canvas')) {
        setPosition([0, -1.9, 0])
        setRotation([Math.PI / 14, -Math.PI / 4, 0])
      }
    }
  }, [model])


  const goodsTitle = () => {
    let goodsEnd = ''
    if (model) {
      console.log(model)
      if (model.name.toLowerCase().includes('mug')) {
        goodsEnd = '머그컵'
      }
      if (model.name.toLowerCase().includes('tshirt')) {
        goodsEnd = '티셔츠'
      }
      if (model.name.toLowerCase().includes('griptok')) {
        goodsEnd = '그립톡'
      }
      if (model.name.toLowerCase().includes('frame')) {
        goodsEnd = '일러스트'
      }
      if (model.name.toLowerCase().includes('phonecase')) {
        goodsEnd = '참고용 폰케이스'
      }
      if (model.name.toLowerCase().includes('canvas')) {
        goodsEnd = '일러스트'
      }
      if (model.name.toLowerCase().includes('poster')) {
        goodsEnd = '포스터'
      }
      if (model.name.toLowerCase().includes('tape')) {
        goodsEnd = '테이프'
      }
      if (model.name.toLowerCase().includes('sticker')) {
        goodsEnd = '스티커'
      }
    }
    return `${illustrator.displayName}님의 ${goodsEnd}`
  }

  return (
    <Container style={{
      zIndex: 1,
      position: 'absolute',
      // backgroundColor: 'blue',
      // bottom: 0,
      // borderRadius: 12,
    }}>
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby='modal-modal-title'
        // aria-describedby='modal-modal-description'
      >
        <div style={{
          display: 'flex', flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          // bottom: '50%',
          transform: 'translate(-50%, -50%)',
          width: responsive(352),
          height: responsive(352) * 1.5,
          // height: `${isMobile ? 90 : 60}vh`,
          // height: `calc(${isMobile ? 70 : 60}vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))`,
          // height: `calc(${isMobile ? 95 : 60}% - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))`,
          border: 'none',
          outline: 'none',
          // backgroundColor: 'red',
        }}>
          {/*<div style={{*/}
          {/*  width: 32, height: 32,*/}
          {/*  backgroundColor: 'white',*/}
          {/*  position: 'sticky',*/}
          {/*  // top: 12,*/}
          {/*  // right: '2vw',*/}
          {/*  borderRadius: 100,*/}
          {/*  display: 'flex', justifyContent: 'center', alignItems: 'center',*/}
          {/*  zIndex: 100,*/}
          {/*  alignSelf: 'flex-end',*/}
          {/*}}*/}
          {/*     onClick={() => {*/}
          {/*       mainStore.setState({ model: null, openModal: false })*/}
          {/*     }}*/}
          {/*>*/}
          {/*  <CloseIcon*/}
          {/*    style={{*/}
          {/*      color: 'black',*/}
          {/*      width: 24, height: 24,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <Box style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{
              width: 30, height: 30, backgroundColor: 'white',
              position: 'absolute',
              zIndex: 999,
              top: 16, right: 12,
              // top: 16,
              // right: '2.5vw',
              borderRadius: 100,
              // border: `1px solid ${Values.colors.black(0.1)}`,
              display: 'flex', justifyContent: 'center', alignItems: 'center',
              alignSelf: 'flex-end',
            }}
            >
              <CloseIcon
                style={{
                  color: 'black',
                  width: 24, height: 24,
                }}
                onClick={() => {
                  console.log('asjdioasjdiosadisjaiodjasio')
                  mainStore.setState({ model: null, openModal: false })
                }}
              />
            </div>
            <div style={{
              height: `${CANVAS_DESCRIPTION_RATIO * 100}%`, backgroundColor: Values.colors.backgroundGrey(),
              borderTopLeftRadius: BORDER_RADIUS, borderTopRightRadius: BORDER_RADIUS,
              marginTop: 8,
            }}>
              <Canvas
                dpr={Math.max(window.devicePixelRatio, 2)}
                // shadowMap
                // shadows='true'
                camera={{
                  fov: 30,
                  // position: [0, 2, 6],
                  // rotation
                }}
              >
                <spotLight
                  position={[-2, 8.5, -1.4]}
                  distance={10}
                  angle={1.5}
                  shadow-bias={-0.0019}
                  penumbra={.5}
                  shadow-camera-near={0.1}
                  shadow-camera-far={20}
                  intensity={1.5}
                  // @ts-ignore
                  focus={0.4}
                  castShadow
                  shadowCameraVisible
                  target={modelRef?.current ?? undefined}
                />
                <ambientLight intensity={0.4} />
                <CameraControl />
                <group ref={modelRef}
                       rotation={rotation}>
                  <ModelRenderer
                    model={model}
                    delta={delta}
                    position={position}
                  />
                </group>
              </Canvas>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', height: `${(1 - CANVAS_DESCRIPTION_RATIO) * 100}%`,
              width: WIDTH,
              backgroundColor: 'white',
              borderBottomLeftRadius: BORDER_RADIUS,
              borderBottomRightRadius: BORDER_RADIUS,
            }}>
              <div style={{
                display: 'flex',
                width: WIDTH,
                // backgroundColor: 'red',
                justifyContent: 'center',
                alignItems: 'center',
                // height: HEIGHT,
                marginTop: responsive(12),
              }}>
                {/*<div*/}
                {/*  style={{*/}
                {/*    paddingLeft: 24,*/}
                {/*    paddingRight: 24,*/}
                {/*    cursor: 'pointer',*/}
                {/*    height: HEIGHT,*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center',*/}
                {/*  }}*/}
                {/*  onClick={async () => {*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <img src={'/icons/chevron-right.png'} style={{*/}
                {/*    width: CHEVRON_WIDTH, height: CHEVRON_HEIGHT,*/}
                {/*    transform: `rotate(${Math.PI}rad)`,*/}
                {/*  }} />*/}
                {/*</div>*/}
                {/*<div style={{*/}
                {/*  width: '100%', fontWeight: 400, fontSize: 32,*/}
                {/*  fontFamily: Values.fonts.nanumPenScript,*/}
                {/*  // backgroundColor: 'red',*/}
                {/*  textAlign: 'center',*/}
                {/*  lineHeight: 1,*/}
                {/*  color: Values.colors.primary(),*/}
                {/*}}>*/}
                {/*  {goodsTitle()}*/}
                {/*</div>*/}
                {/* <div
                  style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    cursor: 'pointer',
                    height: HEIGHT,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={async () => {
                  }}
                >
                  <img src={'/icons/chevron-right.png'} style={{ width: CHEVRON_WIDTH, height: CHEVRON_HEIGHT }} />
                </div>*/}
              </div>
              {/*<div style={{ display: 'flex', flexGrow: 1 }} />*/}
              <div style={{ display: 'flex', alignSelf: 'center', }}>
                <img
                  src={illustrator.backgroundImgs()[0]}
                  // src={'creator-imgs/sororo.webp'}

                  style={{ width: 48, height: 48, borderRadius: 15 }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                  <div style={{ color: '#A5A5A5', fontSize: 13, lineHeight: 1.3, fontWeight: 300, marginTop: 4 }}>
                    Designed by
                  </div>
                  <div style={{ color: 'black', fontSize: 17, fontWeight: 500, lineHeight: 1.3 }}>
                    {illustrator.displayName}
                  </div>
                </div>
              </div>
              {/*<div style={{ display: 'flex', flexGrow: 1 }} />*/}
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: responsive(12),
                marginTop: responsive(4)
              }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 100,
                    padding: responsive(8),
                    backgroundColor: '#F7F7F7',
                  }}>
                  <img
                    src={'/icons/heart.png'}
                    style={{
                      width: responsive(18), height: responsive(16.03),
                    }}
                  />
                  {/*<Favorite style={{*/}
                  {/*  color: '#FD7172',*/}
                  {/*  // width: 24, height: 24*/}
                  {/*}} />*/}
                </div>
                {/*<div style={{ fontWeight: 500, marginLeft: 8, fontSize: 17 }}>12</div>*/}
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </Container>
  )
}


const ModelRenderer = ({
                         model,
                         delta,
                         // rotation,
                         position,
                       }: {
  model: any,
  delta: number,
  // rotation: [number, number, number],
  position: [number, number, number],
}) => {
  // console.log(model)
  if (model) {
// const radius = Math.sqrt()
//       const sinTheta = model.rotation.x
    if (model.type === 'Mesh') {
      return (
        // <PresentationControls
        //   // snap={true}
        //   global={true}
        //   zoom={
        //     1
        //     // zoom
        //   }
        //   // rotation={rotation}
        //   // polar={[0, 1]}
        //   azimuth={
        //     [-Math.PI, Math.PI]
        //   }
        //   config={{ mass: 1, tension: 170, friction: 26 }}
        // >
        <GoodsModel
          {...model}
          // ref={modelRef}
          scale={delta}
          // rotation={rotation}
          // 내가 만든 옵션임
          disableMouseAction={true}
          // position={[xRange/2, -yRange, -zRange/2]}
          // position={[0, -yRange,0]}
          position={position} removeClickIndicator
        />

        // </PresentationControls>
      )
    } else {
      // console.log(model)
      // const delta = 1.6 / Math.max(xRange, yRange, zRange)
      return (
        <GoodsGroupModel
          {...model}
          // ref={modelRef}
          scale={delta}
          // rotation={rotation}
          // 내가 만든 옵션임
          disableMouseAction={true}
          // position={[xRange/2, -yRange, -zRange/2]}
          // position={[0, -yRange,0]}
          position={position}
          removeClickIndicator
        />
      )
    }
  }
  return <mesh
    // ref={modelRef}
  />
}
