import { INSTAGRAM_ID_REGEX, rgbaColor, Values } from '../values'
import { Box, Container, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import mainStore from '../state'
import { isMobile } from 'react-device-detect'
import CloseIcon from '@mui/icons-material/Close'
import { Canvas } from '@react-three/fiber'
import { CameraControl } from '../components/v4/CameraControl'
import Linkify from 'react-linkify'
import { useWindowSize } from '../utils'
import CopyToClipboard from 'react-copy-to-clipboard'


export const CreatorIntro = () => {
  const { responsive, letterSpacing } = useWindowSize()

  const open = mainStore((state) => state.openCreatorIntro)
  const illustrator = mainStore((state) => state.illustrator)
  const [shareMode, setShareMode] = useState(false)
  const [copyDone, setCopyDone] = useState(false)

  const handleOpen = () => {
    mainStore.setState({ openCreatorIntro: true })
  }
  const handleClose = () => {
    mainStore.setState({ openCreatorIntro: false })
  }

  const HEIGHT = 48
  const WIDTH = '100%'
  const BORDER_RADIUS = 12

  console.log(illustrator.bio)

  // const instagramIdParse = (str: string) => {
  //   // const matches = str.match(INSTAGRAM_ID_REGEX)
  //   // if (matches.length > 0) {
  //   //   return <span></span>
  //   // }
  // }

  return (
    <Container style={{
      zIndex: 1,
      position: 'absolute',
      // backgroundColor: 'blue',
      // bottom: 0,
      // borderRadius: 12,
    }}>
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby='modal-modal-title'
        // aria-describedby='modal-modal-description'
      >
        <div style={{
          display: 'flex', flexDirection: 'column',
          position: 'absolute',
          // top: `calc(50% - ${responsive(360) * 1.4 / 4}px)`,
          top: '50%',
          left: '50%',
          // bottom: '50%',
          transform: 'translate(-50%, -50%)',
          width: responsive(352),
          height: responsive(352) * 1.5,
          // height: responsive(360) * 1.45,
          // width: `${isMobile ? 95 : 70}vw`,
          // height: `${isMobile ? 90 : 60}vh`,
          // height: `calc(${isMobile ? 70 : 60}vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))`,
          // height: `calc(${isMobile ? 65 : 30}% - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))`,
          border: 'none',
          outline: 'none',
          // backgroundColor: 'red',
        }}>
          {/*<div style={{*/}
          {/*  width: 32, height: 32,*/}
          {/*  backgroundColor: 'white',*/}
          {/*  position: 'sticky',*/}
          {/*  // top: 12,*/}
          {/*  // right: '2vw',*/}
          {/*  borderRadius: 100,*/}
          {/*  display: 'flex', justifyContent: 'center', alignItems: 'center',*/}
          {/*  zIndex: 100,*/}
          {/*  alignSelf: 'flex-end',*/}
          {/*}}*/}
          {/*     onClick={() => {*/}
          {/*       mainStore.setState({ model: null, openModal: false })*/}
          {/*     }}*/}
          {/*>*/}
          {/*  <CloseIcon*/}
          {/*    style={{*/}
          {/*      color: 'black',*/}
          {/*      width: 24, height: 24,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <Box style={{
            flexGrow: 1,
            width: '100%',
            height: '100%',
            display: 'flex', flexDirection: 'column',
          }}>
            <div style={{
              width: 30, height: 30, backgroundColor: 'white',
              position: 'absolute',
              top: responsive(24), right: responsive(12),
              // top: 16,
              // right: '2.5vw',
              borderRadius: 100,
              // border: `1px solid ${Values.colors.black(0.1)}`,
              display: 'flex', justifyContent: 'center', alignItems: 'center',
              alignSelf: 'flex-end',
            }}
            >
              <CloseIcon
                style={{
                  color: 'black',
                  width: responsive(24), height: responsive(24),
                }}
                onClick={() => {
                  mainStore.setState({ openCreatorIntro: false })
                }}
              />
            </div>
            {shareMode ? <div style={{
                display: 'flex', flexDirection: 'column', height: `100%`,
                justifyContent: 'center',
                marginTop: 12,
                width: WIDTH,
                backgroundColor: '#FEFEFE',
                borderRadius: BORDER_RADIUS,
                // borderBottomLeftRadius: BORDER_RADIUS,
                // borderBottomRightRadius: BORDER_RADIUS,
              }}>
                <img
                  src={'/icons/back.png'}
                  style={{
                    objectFit: 'contain', width: responsive(24), padding: responsive(8),
                    // marginTop: responsive(12),
                    // marginLeft: responsive(12),
                    position: 'absolute',
                    top: responsive(24), left: responsive(12),
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setShareMode(false)
                  }}
                />
                <div style={{
                  fontSize: responsive(25), fontWeight: 700, textAlign: 'center',
                  marginTop: responsive(25),
                }}>
                  {illustrator.displayName}님의 룸을<br />공유해 응원 해주세요!
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: responsive(16),
                  marginRight: responsive(16),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <div
                    style={{
                      // flexGrow: 1,
                      fontSize: responsive(17), fontWeight: 500, color: '#ABABAB',
                      // width: '100%',
                      textOverflow: 'ellipsis',
                      marginTop: responsive(12),
                    }}>
                    https://metaverse-fair.io/?c={illustrator.nameDev}
                  </div>
                  <CopyToClipboard
                    text={`https://metaverse-fair.io/?c={illustrator.nameDev}`}
                    onCopy={() => {
                      // console.log('asjdioasjdoaoidjaiodjsaio')
                      setCopyDone(true)
                      setTimeout(() => {
                        setCopyDone(false)
                      }, 2000)
                    }}>
                    <div
                      style={{
                        backgroundColor: '#5C39F1', fontSize: responsive(17),
                        fontWeight: 500,
                        color: 'white',
                        // height: responsive(40),
                        width: responsive(164),
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        marginTop: responsive(32),
                        paddingTop: responsive(10),
                        paddingBottom: responsive(12),
                        borderRadius: responsive(12),
                      }}>
                      <span>
                      Copy
                      </span>
                    </div>
                  </CopyToClipboard>
                  {
                    copyDone ?
                      <div
                        style={{
                          marginTop: responsive(24), position: 'absolute', bottom: responsive(24),
                          color: 'white',
                          fontWeight: 500,
                          backgroundColor: Values.colors.black(0.25),
                          padding: responsive(2),
                          paddingInline: responsive(12),
                          borderRadius: 100,
                        }}>
                        클립보드에 복사했습니다
                      </div>
                      : undefined
                  }
                </div>
              </div>
              : <div style={{
                display: 'flex', flexDirection: 'column', height: `100%`,
                marginTop: 12,
                width: WIDTH,
                backgroundColor: '#FEFEFE',
                borderRadius: BORDER_RADIUS,
                justifyContent: 'center',
                // borderBottomLeftRadius: BORDER_RADIUS,
                // borderBottomRightRadius: BORDER_RADIUS,
              }}>
                <div style={{ flexGrow: 1 }}></div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: responsive(32),
                }}>
                  <img
                    src={illustrator.backgroundImgs()[0]}
                    // src={'creator-imgs/sororo.webp'}

                    style={{
                      width: responsive(182),
                      height: responsive(182),
                      objectFit: 'fill',
                      borderRadius: BORDER_RADIUS,
                      // borderTopLeftRadius: BORDER_RADIUS,
                      // borderTopRightRadius: BORDER_RADIUS,
                      // ,borderBottomLeftRadius: BORDER_RADIUS
                      // height: '100%',
                      // aspectRatio: '1 / 1',
                      // border: '1px solid red',
                      // borderRadius: 15,
                    }}
                  />
                  <div style={{
                    display: 'flex', flexDirection: 'column',
                    marginLeft: responsive(20), justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'red'
                  }}>
                    <div style={{
                      backgroundColor: '#FD7172',
                      width: responsive(44),
                      height: responsive(44),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 100,
                    }}>
                      <img src={'/icons/whiteHeart.png'} style={{
                        objectFit: 'contain',
                        width: responsive(24),
                      }} />
                    </div>
                    <div
                      style={{
                        marginTop: responsive(12),
                        backgroundColor: 'black',
                        width: responsive(44),
                        height: responsive(44),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 100,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShareMode(true)
                      }}
                    >
                      <img src={'/icons/shareIcon.png'} style={{
                        objectFit: 'contain',
                        width: responsive(24),
                      }} />
                    </div>
                  </div>
                </div>
                <div style={{
                  display: 'flex', flex: 1, flexDirection: 'column',
                  marginLeft: responsive(16),
                  marginRight: responsive(16),
                  marginTop: responsive(4),
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}>
                  {/*<div style={{ color: Values.colors.black(0.8), fontSize: 14, lineHeight: 1.3, fontWeight: 300 }}>*/}
                  {/*  Designed by*/}
                  {/*</div>*/}
                  <div style={{
                    color: Values.colors.black(), fontSize: responsive(32), fontWeight: 500, lineHeight: 1.1,
                    // fontFamily: Values.fonts.nanumPenScript,
                  }}>
                    {illustrator.displayName}
                  </div>
                  <div style={{
                    color: '#ABABAB', fontSize: responsive(15), fontWeight: 400, lineHeight: 1.3,
                    marginTop: responsive(16),
                    marginBottom: responsive(40),
                    width: `calc(100% - ${responsive(24)}px)`,
                    // backgroundColor: 'red',
                    textAlign: 'center',
                    overflowY: 'scroll',
                    maxHeight: responsive(200),
                    // fontFamily: Values.fonts.nanumPenScript,
                  }}>
                    <Linkify
                      // @ts-ignore
                      properties={{
                        target: '_blank', style: {
                          color: Values.colors.greyBorder(),
                          overflowY: 'scroll',
                          // height: '100%',
                          // backgroundColor: 'red'
                        },
                      }}
                    >
                      {illustrator.bio.split('\n').map((line, idx) => {
                        return (<span key={`ILLUSTRATOR_BIO_WITH_IDX_KEY_${idx}`}>{line}<br /></span>)
                      })}
                    </Linkify>
                  </div>
                </div>
                <div style={{ flexGrow: 1 }}></div>
              </div>}
          </Box>
        </div>
      </Modal>
    </Container>
  )
}
// 나중에 보자...
//
// input:
//   "안녕하세요. 귀여운 미니 픽셀 세상을 만드는 미선 작가입니다.
// 조그맣고 앙증맞은 미니선데이에서 느긋한 시간 보내세요 :3
//
// Instagram : @ordinaryms_
//   Twitter : @ordinaryms_
// Homepage : minisunday.studio"
//
// output: <span>
//   안녕하세요. 귀여운 미니 픽셀 세상을 만드는 미선 작가입니다.
//   <br/>
// 조그맣고 앙증맞은 미니선데이에서 느긋한 시간 보내세요 :3
//     <br/>
//     <br/>
//   Instagram : <span onClick={() => {
//     window.location = 'https://instagram.com/ordinaryms_'
// }}>@ordinaryms_</span>
//   Twitter : <span onClick={() => {
//   window.location = 'https://instagram.com/ordinaryms_'
// }}>@ordinaryms_</span>@ordinaryms_
// Homepage : minisunday.studio
// </span>
