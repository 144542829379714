import React, { forwardRef, useEffect, useState } from 'react'
import mainStore from '../../state'
import { Material } from 'three'
import { ClickIndicator } from './elements/click-indicator'

export const GoodsGroupModel = forwardRef(
  ({
     disableMouseAction = false,
     setBounds = false,
     removeClickIndicator = false,
     ...props
   }: { props: any, disableMouseAction?: boolean, setBounds?: boolean, removeClickIndicator?: boolean }, ref) => {
    const [hovered, setHover] = useState(false)
    useEffect(() => {
      // console.log(props);
      // if (ref) {
      //   // @ts-ignore
      //   // console.log(ref.current.geometry)
      // }
      // @ts-ignore
      // props.children.map((child, idx) => {
      //   const xRange = child.geometry.boundingBox.max.x - child.geometry.boundingBox.min.x
      //   const yRange = child.geometry.boundingBox.max.y - child.geometry.boundingBox.min.y
      //   const zRange = child.geometry.boundingBox.max.z - child.geometry.boundingBox.min.z
      //   const delta = 1.6 / Math.max(xRange, yRange, zRange)
      // })
    }, [])
    const ModelMaterial = ({ material }: { material: Material }) => {
      if (hovered) return <meshStandardMaterial color='rgb(66, 133, 244)' opacity={1} transparent />
      // @ts-ignore
      if (material.type.includes('Basic')) {
        // @ts-ignore
        return <meshBasicMaterial {...material} />
      }
      // @ts-ignore
      return <meshStandardMaterial {...material} />
    }
    // @ts-ignore
    // if (model?.uuid === props?.uuid) {
    //   return <mesh></mesh>
    // }

    const onClick = () => {
      // @ts-ignore
      if (props.name.toLowerCase().includes('Goods_FrameOnDesk'.toLowerCase())){
        mainStore.setState({
          openCreatorIntro: true,
        })
        return;
      }
      if (!disableMouseAction)
        mainStore.setState({
          model: { ...props, position: [0, 0, 0] },
          openModal: true,
        })
    }
    return (
      <group
        {...props}
        onPointerOver={(e) => {
          if (!disableMouseAction) {
            setHover(true)
          }
        }}
        onPointerOut={() => {
          if (!disableMouseAction) {
            setHover(false)
          }
        }}
        onClick={() => {
          onClick()
        }}
        // rotation={[0, 0, 0]}
      >
        {/*<mesh*/}
        {/*  ref={ref}*/}
        {/*  matrixAutoUpdate={false}*/}
        {/*  // @ts-ignore*/}
        {/*  // geometry={mode.geometry}*/}
        {/*  // @ts-ignore*/}
        {/*  // material={model.material}*/}
        {/*  // position={[0,0,0]}*/}
        {/*  // rotation={[Math.PI / 2, -Math.PI / 9, 0]}*/}
        {/*  {...props}*/}
        {/*>*/}
        {/*  <ModelMaterial />*/}
        {/*</mesh>*/}
        {/*/!*@ts-ignore*!/*/}
        {
          !removeClickIndicator ? <ClickIndicator
            // @ts-ignore
            name={props.name}
            // @ts-ignore
            position={props.children[0].position}
            // @ts-ignore
            rotation={props.children[0].rotation}
            // rotation={new Euler(0, 0, 0)}
            // @ts-ignore
            geometry={props.children[0].geometry}
            // @ts-ignore
            scale={props.children[0].scale}
            onClick={onClick}
          /> : <mesh />
        }
        {/*@ts-ignore*/}
        {props.children.map((child, idx) => {
          const childKey = `MODEL_SCENE_CHILD_SECOND_WITH_IDX_${idx}_${child.uuid}`
          return (
            <mesh key={childKey} {...child} >
              <ModelMaterial material={child.material} />
            </mesh>
          )
        })}
      </group>
    )
  })


