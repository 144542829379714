export const rgbaColor = (r: number, g: number, b: number, a?: number) => `rgba(${r}, ${g}, ${b}, ${a || '1'})`

export class Values {
  static fonts = {
    // openSans: 'Open Sans',
    // nanumPenScript: 'Nanum Pen Script',
    gothicA1: 'Gothic A1',
    dgm: "DGM"
  }
  static colors = {
    bold: (opacity?: number) => rgbaColor(72, 72, 72, opacity),
    foggy: (opacity?: number) => rgbaColor(118, 118, 118, opacity),
    accent: (opacity?: number) => rgbaColor(236, 102, 100, opacity),
    shadow: (opacity?: number) => rgbaColor(105, 105, 105, opacity),
    secondary: (opacity?: number) => rgbaColor(236, 102, 100, opacity),
    secondaryLight: (opacity?: number) => rgbaColor(255, 120, 118, opacity),
    primaryVariant: (opacity?: number) => rgbaColor(96, 135, 250, opacity),
    primary: (opacity?: number) => rgbaColor(163, 204, 134, opacity),
    black: (opacity?: number) => rgbaColor(51, 51, 51, opacity),
    background: (opacity?: number) => rgbaColor(251, 251, 251, opacity),
    backgroundGrey: (opacity?: number) => rgbaColor(247, 247, 247, opacity),
    viewBackground: (opacity?: number) => rgbaColor(224, 224, 224, opacity || 0.19),
    navigation: (opacity?: number) => rgbaColor(251, 251, 251, opacity),
    greyBorder: (opacity?: number) => rgbaColor(112, 112, 112, opacity),
  }
}


export const IS_DEV: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const INSTAGRAM_ID_REGEX = /\B@[a-z0-9_-]+/gi;


