import * as THREE from 'three'
import { BufferGeometry, Euler } from 'three'
import React, { useEffect, useRef, useState } from 'react'
import { Html } from '@react-three/drei'

export const ClickIndicator = (
  { name, position, geometry, onClick }:
    {
      name: string, position: Euler, geometry: BufferGeometry,
      onClick?: Function
    },
) => {
  const ref = useRef(null)
  const [scale, setScale] = useState(0.05)
  const [rotation, setRotation] = useState(new Euler(Math.PI, 0, 0))
  const [iconPath, setIconPath] = useState('/icons/more.png')
  const [iconDimension, setIconDimension] = useState({ width: 26, height: 12 })
  const [showClickIndicator, setShowClickIndicator] = useState(true)

  // @ts-ignore
  // const yRange = props.geometry.boundingBox.max.y - props.geometry.boundingBox.min.y
  useEffect(() => {
    // const yRange = geometry.boundingBox.max.y - geometry.boundingBox.min.y
    let xRange = 0
    let yRange = 0
    let zRange = 0
    if (name.includes('HangingPoster')) {
      // console.log('SJIODJSAODIODJIODIOSAJDIOAIO')
      yRange = 0.4
      setScale(5)
      xRange = 0.7
      // setRotation(new Euler(Math.PI, 0, Math.PI))
      setRotation(new Euler(Math.PI / 2, 0, 0))
      // zRange = 0.5
    } else if (name.includes('Goods_Frame_')) {
      if (name.includes('002') || name.includes('004')) {
        // yRange = 0.1
        zRange = -1
        // xRange = -0.5
        setRotation(new Euler(-Math.PI / 2, 0, 0))
        setScale(0.2)
        // xRange = -1
      } else {
        yRange = 0.3
      }

    } else if (name.includes('Stickers')) {
      yRange = 0.25
      setScale(0.13)
    } else if (name.includes('FrameOnDesk')) {
      yRange = 0.22
      setIconPath('/icons/touch.png')
      setIconDimension({ width: 26, height: 26 })
    } else if (name.includes('TShirt')) {
      yRange = 0.12
    } else if (name.includes('Mug')) {
      yRange = 0.3
    } else if (name.includes('Griptok_002')) {
      yRange = 0.2
    } else if (name.includes('Griptok_001')) {
      yRange = 0.23
    } else if (name.includes('Tape')) {
      yRange = 0.23
    } else if (name.includes('Goods_Canvas')) {
      // console.log(rotation)
      // yRange = 2000
      // xRange = 200
      // zRange = 200
      // yRange = -100
      // xRange = -0
      zRange = -550
      setRotation(new Euler(Math.PI / 2, 0, 0))
      setScale(25)
    } else {
      setShowClickIndicator(false)
    }
    // @ts-ignore
    // const indicatorHeight = ref.current.geometry.boundingBox.max.y - ref.current.geometry.boundingBox.min.y
    const transformation = new THREE.Matrix4().makeTranslation(xRange, yRange, zRange)
    // console.log(transformation)
    // console.log(ref)
    if (ref) {
      ref.current.applyMatrix4(transformation)
    }
  }, [])
  // @ts-ignore
  // props.applyMatrix(transformation)
  if (!showClickIndicator) {
    return <mesh />
  }
  return (
    <mesh
      ref={ref}
      scale={scale}
      position={
        // // @ts-ignore
        // [props.position.x, props.position.y + yRange, props.position.z]
        [position.x, position.y, position.z]
      }
      rotation={rotation}
      // onClick={() => {
      //   if (onClick) onClick()
      // }}
    >
      <Html>
        <img
          src={iconPath} style={{ ...iconDimension, cursor: 'pointer' }}
          onClick={() => {
            // console.log('asdjiosadjiosadiosajdiosajiodsjaioajsio')
            if (onClick) onClick()
          }}
        />
      </Html>
    </mesh>
    // <group
    //   ref={ref}
    //   scale={scale}
    //   // scale={[0.1, 0.1, 0.1]}
    //   // scale={[0.3, 0.3, 0.3]}
    //   position={
    //     // // @ts-ignore
    //     // [props.position.x, props.position.y + yRange, props.position.z]
    //     [position.x, position.y, position.z]
    //   }
    //   rotation={rotation}
    //   onClick={() => {
    //     console.log('sjadiopsajdoasiodjsaiodjasiojio');
    //     if (onClick) onClick()
    //   }}
    // >
    //   <IndicatorGeometry />
    // </group>
  )

}
