import { rgbaColor, Values } from '../values'
import { Menu, MenuItem, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import mainStore from '../state'
import MenuIcon from '@mui/icons-material/Menu'
import { Illustrators } from '../values/illustrators'

export const ShowCreatorList = () => {
  const illustrator = mainStore((state) => state.illustrator)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div style={{
      // display: 'flex',
      // position: 'fixed',
      // left: `16px`,
      // top: 16,
    }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          right: 16,
          top: 16,
          borderRadius: 16,
          backgroundColor: rgbaColor(0,0,0,0.35),
          cursor: 'pointer',
          width: 44,
          height: 44,
          objectFit: 'contain',
        }}
        onClick={handleClick}
      >
        {/*<MenuIcon />*/}
        <img src={'/icons/hamburger.png'} style={{width: 16}}/>
      </div>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 6,
            width: '20ch',
          },
        }}
      >
        {Illustrators.map((option) => (
          <MenuItem
            key={`CREATOR_LIST_INDEX_KEY_${option.nameDev}`}
            selected={option.nameDev === illustrator.nameDev}
            onClick={() => {
              // console.log(window.location);
              // mainStore.setState({ illustrator: option })
              // handleClose()
              // @ts-ignore
              window.location = window.location.origin + `/?c=${option.nameDev}`
            }}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>
      {/*<CreatorListDialog*/}
      {/*  open={openDialog}*/}
      {/*  handleClose={() => {*/}
      {/*    setOpenDialog(false)*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  )
}

