import { IS_DEV, Values } from '../values'
import { Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

export const InitialIndicator = () => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (isMobile) {
      if (IS_DEV) {
        setOpen(true)
      } else {
        const iid = localStorage.getItem('@shownInitialIndicator')
        if (!iid) {
          setOpen(true)
          localStorage.setItem('@shownInitialIndicator', 'true')
        }
      }
    }
  }, [])
  return (
    <div style={{
      // display: 'flex',
      // position: 'fixed',
      // left: `16px`,
      // top: 16,
    }}>
      <IndicatorModal
        open={open}
        handleClose={() => {
          setOpen(false)
        }}
      />
    </div>
  )
}

const IndicatorModal = ({ open, handleClose }: { open: boolean; handleClose(): void }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      style={{
        // width: 300
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 17,
          width: 343,
          height: 355,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: 21,
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleClose()
        }}
      >
        <img
          src={'/indicators/one-finger.png'}
          style={{
            width: '100%',
          }}
        />
        <img
          src={'/indicators/two-fingers.png'}
          style={{
            width: '100%',
            marginTop: 36,
          }}
        />
      </div>
    </Modal>
  )
}
