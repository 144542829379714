import create from 'zustand'
import { Vector3 } from 'three'
import { ERoomTypes, Illustrators } from '../values/illustrators'
import { IIllustrator } from '../types/interfaces'

const mainStore = create((set) => {
  const obj: {
    floor: number;
    openModal: boolean;
    openCreatorIntro: boolean;
    model?: any;
    illustrator: IIllustrator;
  } = {
    floor: 0,
    openModal: false,
    openCreatorIntro: false,
    model: null,
    illustrator: Illustrators[Illustrators.length - 1],
  }
  return obj
})

export default mainStore
