import { cameraController, FLOOR_DATA } from '../state/camera'
import mainStore from '../state'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Values } from '../values'

export const FloorButton = () => {
  const floor = mainStore((state) => state.floor)
  const HEIGHT = 56
  const WIDTH = 200

  const roomTitle = () => {
    switch (floor) {
      case 0:
        return '소개룸'
      case 1:
        return '전시룸'
      case 2:
        return '굿즈룸'
    }
  }
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      position: 'fixed',
      left: `calc(50% - ${WIDTH / 2}px)`,
      width: WIDTH,
      bottom: 48,
      backgroundColor: 'white',
      borderRadius: 100,
      color: Values.colors.black(),
    }}>
      {/*<button*/}
      {/*  style={{*/}
      {/*    position: 'fixed',*/}
      {/*    left: '50%',*/}
      {/*    bottom: 40,*/}
      {/*    border: 'none',*/}
      {/*    padding: 12,*/}
      {/*    backgroundColor: 'red',*/}
      {/*    width: 120,*/}
      {/*    marginLeft: -60,*/}
      {/*    color: 'white',*/}
      {/*    cursor: 'pointer',*/}
      {/*  }}*/}
      {/*  onClick={() => {*/}
      {/*    // mainStore.setState({ openModal: true })*/}
      {/*    if (cameraController.currentFloor === FLOOR_DATA.length - 1) {*/}
      {/*      cameraController.reset()*/}
      {/*      mainStore.setState({ floor: 0 })*/}
      {/*      return*/}
      {/*    }*/}
      {/*    cameraController.ascend()*/}
      {/*    mainStore.setState({ floor: floor + 1 })*/}
      {/*  }}*/}
      {/*>*/}
      {/*  카메라포지션 변경*/}
      {/*</button>*/}
      <div
        style={{
          padding: 8,
          paddingLeft: 20,
          cursor: 'pointer',
          // opacity: floor === 0 ? 0.5 : 1,
          height: HEIGHT,
        }}
        onClick={async () => {
          console.log(floor)
          if (floor === 0) {
            return
          }
          // if (floor === FLOOR_DATA.length - 1) {
          //   console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@');
          //   cameraController.reset()
          //   mainStore.setState({ floor: 0 })
          //   return
          // }
          await cameraController.descend()
          mainStore.setState({ floor: floor - 1 })
        }}
      >
        {/*<ArrowDownwardIcon style={{ opacity: floor === 0 ? 0.5 : 1 }} />*/}
        <img src={'/icons/arrowDown.png'} style={{ width: 16, opacity: floor === 0 ? 0.5 : 1 }} />
      </div>
      <div style={{
        width: '100%', height: HEIGHT, fontWeight: 600, fontSize: 17,
        // fontFamily: Values.fonts.nanumPenScript,
      }}>
        {roomTitle()}
      </div>
      <div
        style={{
          padding: 8,
          paddingRight: 20,
          cursor: 'pointer',
          // opacity: floor === FLOOR_DATA.length - 1 ? 0.5 : 1,
          height: HEIGHT,
        }}
        onClick={async () => {
          if (floor === FLOOR_DATA.length - 1) {
            return
          }
          await cameraController.ascend()
          mainStore.setState({ floor: floor + 1 })
        }}
      >
        <img src={'/icons/arrowUp.png'} style={{ width: 16, opacity: floor === FLOOR_DATA.length - 1 ? 0.5 : 1 }} />

        {/*<ArrowUpwardIcon style={{ opacity: floor === FLOOR_DATA.length - 1 ? 0.5 : 1 }} />*/}
      </div>

    </div>
  )
}
