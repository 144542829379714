import React, { useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ViewApp } from './view-app'
import { FloorButton } from './floor-button'
import { BackToZepButton } from './back-to-zep'
import { CreatorIntro } from './creator-intro'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
})


export const ViewIndex = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ViewApp />
      <CreatorIntro />
    </ThemeProvider>
  )
}
